import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/pentech.hu/documentation-of-integrations/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Sales Partner API`}</h1>
    <PageDescription mdxType="PageDescription">
    A dokumentum célja, hogy bemutassa a Péntech Sales Partner API integrálható interfész használatát.
    </PageDescription>
    <h2>{`Áttekintés`}</h2>
    <p>{`Az API segítségével külső partnernél vezetett számlát lehet a Péntech rendszerébe beküldeni, ezzel faktorálási igénylést indítani.`}</p>
    <h2>{`Egyszerűsített folyamat`}</h2>
    <ol>
      <li parentName="ol">{`A felhasználó a számlavezető rendszerben rányom egy gombra, amivel egy előzetes ajánlatot kap.`}</li>
      <li parentName="ol">{`Amennyiben ezt elfogadja, a számlavezető rendszer átirányítja a Péntech rendszerébe…`}</li>
      <li parentName="ol">{`ahol autentikáció után igényelheti is a faktorálási szolgáltatást, `}</li>
      <li parentName="ol">{`vagy ha korábban még nem kötött faktoring keretszerződést, megköthet azt a Péntech platformján.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      